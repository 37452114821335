import React from 'react';

interface ISEOMeta {
  locale?: string;
  locales?: string[];
  title?: string;
  ogTitle?: string;
  ogImage?: string;
  description?: string;
  keywords?: string;
  host?: string;
  path?: string;
  ignoreLocale?: boolean; // For builder pages with single locale
  isFromBuilder?: boolean;
}

/**
 * Since current locale from business in nextjs is not match HTML lang-locale standard, we do some process for compatible with standard
 * ref: https://c1ey4wdv9g.larksuite.com/wiki/wikusCtEHKf3q4qR3VAHE16tBVe
 * @param locale current locale from nextjs
 * @param isFromBuilder
 * @returns format lang
 */
export const getSeoFormatLang = (locale: string, isFromBuilder = false) => {
  let formatLocale = locale || '';
  if (formatLocale.toLowerCase() === 'fil-ph') {
    formatLocale = 'tl';
  }
  if (formatLocale.toLowerCase() === 'es-419') {
    formatLocale = 'es';
  }
  return isFromBuilder ? formatLocale.toLowerCase() : formatLocale;
};

export const getMetasAndLinks = ({
  locale,
  locales,
  title,
  ogTitle,
  ogImage,
  description,
  keywords,
  host,
  path,
  isFromBuilder = false,
  ignoreLocale = false
}: ISEOMeta) => {
  // Common links
  const links = [
    {
      hid: `canonical-lang-${locale}`,
      rel: 'canonical',
      href: 'https://www.safex.hk/zh-CN/'
    },
    {
      hid: 'alternate-hreflang-x-default',
      rel: 'alternate',
      href: 'https://www.safex.hk/zh-CN/',
      hrefLang: 'x-default'
    }
  ];
  // Common metas
  const metas = [
    {
      name: 'viewport',
      content:
        'width=device-width, user-scalable=no, initial-scale=1, minimum-scale=1, maximum-scale=1, viewport-fit=cover',
      key: 'viewport'
    },
    {
      name: 'renderer',
      content: 'webkit',
      key: 'renderer'
    },
    // {
    //   name: 'robots',
    //   content:
    //     banSpiderRoutes.indexOf(path) === -1 &&
    //     (host || '').indexOf(banSpiderHost) === -1
    //       ? 'index,follow'
    //       : 'noindex',
    //   key: 'robots'
    // },
    {
      name: 'description',
      content: description || title,
      key: 'description'
    },
    {
      name: 'keywords',
      content: keywords || title,
      key: 'keywords'
    },
    {
      property: 'og:type',
      content: 'website',
      key: 'og:type'
    },
    {
      property: 'og:title',
      content: ogTitle || title,
      key: 'og:title'
    },
    {
      property: 'og:description',
      content: description || title,
      key: 'og:description'
    },
    // {
    //   property: 'og:url',
    //   content: currentCompletePath,
    //   key: 'og:url'
    // },
    {
      hid: 'og:locale',
      property: 'og:locale',
      content: getSeoFormatLang(locale, isFromBuilder).replace(/-/g, '_'),
      key: 'og:locale'
    },
    {
      property: 'twitter:title',
      content: ogTitle || title,
      key: 'twitter:title'
    },
    {
      property: 'twitter:description',
      content: description || title,
      key: 'twitter:description'
    }
    // {
    //   property: 'twitter:site',
    //   content: '@Mufex_Official',
    //   key: 'twitter:site'
    // },
    // {
    //   property: 'twitter:creator',
    //   content: '@Mufex_Official',
    //   key: 'twitter:creator'
    // }
    // {
    //   name: 'yandex-verification',
    //   content: '562817e58c38d16a', //
    //   key: 'yandex-verification'
    // },
    // {
    //   name: 'google-site-verification',
    //   content: 'cimlT5XFEqGBsSVh8XQe9sP-l8Z0mZ6UpeYSt612aHw', //
    //   key: 'google-site-verification'
    // },
    // {
    //   name: 'naver-site-verification',
    //   content: '520acf3890720b693406a160ba60807d6c9741c1', //
    //   key: 'naver-site-verification'
    // },
    // {
    //   name: 'baidu-site-verification',
    //   content: 'YMDABwHltA', //
    //   key: 'baidu-site-verification'
    // }
  ];
  metas.push(
    ...locales.map((lang) => {
      return {
        hid: `og:locale:alternate-${getSeoFormatLang(lang, isFromBuilder)}`,
        property: 'og:locale:alternate',
        content: getSeoFormatLang(lang, isFromBuilder).replace(/-/g, '_'),
        key: `og:locale:alternate-${lang}`
      };
    })
  );

  // links.push(
  //   ...locales
  //     .filter((lang) => lang !== 'ed-ED')
  //     .map((lang) => {
  //       return {
  //         hid: `alternate-hreflang-${getSeoFormatLang(lang, isFromBuilder)}`,
  //         rel: 'alternate',
  //         href: `${linkHost}${getLocaleStr(lang, ignoreLocale, isFromBuilder)}${
  //           path || ''
  //         }`,
  //         hrefLang: getSeoFormatLang(lang, isFromBuilder)
  //       };
  //     })
  // );

  // Add image while share
  if (ogImage) {
    metas.push({
      property: 'og:image',
      content: ogImage,
      key: ogImage
    });
    metas.push({
      property: 'twitter:image',
      content: ogImage,
      key: `${ogImage}-twitter`
    });
    metas.push({
      property: 'twitter:image:src',
      content: ogImage,
      key: `${ogImage}-twitter-src`
    });
    metas.push({
      name: 'image',
      content: ogImage,
      key: `${ogImage}-image`
    });
    metas.push({
      property: 'twitter:card',
      content: 'summary_large_image',
      key: `${ogImage}-card`
    });
  }

  return (
    <>
      {metas.map((m, index) => (
        <meta key={index} {...m} />
      ))}
      {links.map((l, index) => (
        <link key={index} {...l} />
      ))}
    </>
  );
};
